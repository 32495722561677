<!-- eslint-disable vue/max-len -->
<script setup lang="ts">
import { HubsterOrderTotal } from '@slabcode/hubster-models/hubster/payloads/manager_orders/create-order';
import { PaymentMethod } from '@slabcode/hubster-models/enums/hubster';
import { formatCurrency } from '@/common/utils';
import { Customer, CustomerItem } from '@/modules/orders/interfaces';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const cartStore = useCartStore();
const menuStore = useMenuStore();
const webhookStore = useWebhookStore();
const languageStore = useLanguageStore();
const inactiveStore = useInactiveStore();
const metadataStore = useMetadataStore();
const orderTypeStore = useOrderTypeStore();
const { triggerGoBack } = useGTMEventsComposable();
const { productsCurrency } = storeToRefs(menuStore);
const { currentLanguage } = storeToRefs(languageStore);

const { customer, jobResponse, paymentInProgress } = storeToRefs(webhookStore);
const { isPickupOrder, fulfillmentMode, tableNumber } = storeToRefs(orderTypeStore);
const { hideCash, paymentImage, priceDecimals, clusterSettings, showClientType } = storeToRefs(metadataStore);

const hideSummary = ref(false);
const showUserInfo = ref(false);
const showBackActions = ref(true);

function calcItemsSubtotal(items: any[]): number {
  const result = items.reduce((acc, curr) => {
    const price = curr.price ?? 0;
    const { quantity } = curr;
    // eslint-disable-next-line vue/max-len
    const modifierTaxes = (curr.modifiers?.length ? calcItemsSubtotal(curr.modifiers) : 0) * quantity;
    const productTaxes = (price * quantity) / (1 + curr.tax.percentageValue.decimalValue);

    return acc + modifierTaxes + productTaxes;
  }, 0);

  return result;
}

const totalTaxes = computed((): Partial<HubsterOrderTotal> => {
  const subtotal = calcItemsSubtotal(cartStore.items);
  const tax = cartStore.getTotal() - subtotal;
  const total = cartStore.getTotal();

  return {
    tax,
    subtotal,
    total,
  };
});

const currencyValue = (value: number) => formatCurrency(
  productsCurrency.value,
  value,
  priceDecimals.value,
);

const orderType = computed(() => {
  if (isPickupOrder) return t('TAKE_AWAY');

  return tableNumber.value ? `${t('EAT_HERE')} - ${t('TABLE')} ${tableNumber.value}` : t('EAT_HERE');
});

const itemsWithCurrency = computed(() =>
  cartStore.items.map((item) => ({
    ...item,
    price: currencyValue(item.price ?? 0),
  })));

// eslint-disable-next-line max-lines-per-function
const userFields = computed((): Map<keyof Customer, CustomerItem> => {
  const values = new Map();
  const isNaturalPerson = customer.value.clientType === 'false';

  const fields: Partial<Record<keyof Customer, CustomerItem>> = {
    name: {
      label: t('USER_INFO.NAME'),
      type: 'text',
      icon: 'account',
      info: t('USER_INFO.VALIDATIONS.BILLING_NAMES_NEEDED'),
      placeholder: (showClientType.value && isNaturalPerson) ? t('USER_INFO.NAME_PLACEHOLDER') : t('USER_INFO.COMPANY_NAME'),
    },
    taxIdentificationNumber: {
      label: t('USER_INFO.DOCUMENT_ID'),
      type: 'number',
      icon: 'citizen-id',
      info: t('USER_INFO.VALIDATIONS.BILLING_ID_NEEDED'),
      placeholder: t('USER_INFO.DOCUMENT_PLACEHOLDER'),
    },
    phone: {
      label: t('USER_INFO.PHONE'),
      type: 'number',
      icon: 'mobile',
      info: '',
      placeholder: t('USER_INFO.PHONE_PLACEHOLDER'),
    },
    email: {
      label: t('USER_INFO.EMAIL'),
      type: 'email',
      icon: 'email',
      info: t('USER_INFO.VALIDATIONS.BILLING_NEEDED'),
      placeholder: t('USER_INFO.EMAIL_PLACEHOLDER'),
    },
  };

  // Set default field
  values.set('name', fields.name);

  if (clusterSettings.value?.customerInfo) {
    // Set values
    const { customerInfo } = clusterSettings.value;
    const keys = Object.keys(customerInfo) as Array<keyof Customer>;

    keys.forEach((key) => {
      // @ts-ignore
      if (customerInfo[key] && fields[key]) values.set(key, fields[key]);
    });
  }

  return values;
});

/**
 * Create a payment order with specific payment method
 */
function selectPaymentMethod(isCash: boolean) {
  const { storeId } = route.params;
  const { taxIdentificationNumber, clientType } = customer.value;

  if (!storeId) return;

  // Update timer to 90
  inactiveStore.changeInactiveTime('timer', 90);

  webhookStore.createOrder({
    storeId: storeId as string,
    items: cartStore.items,
    orderTotal: totalTaxes.value,
    paymentMethod: isCash ? PaymentMethod.CASH : PaymentMethod.CARD,
    customer: {
      ...customer.value,
      taxIdentificationNumber: showClientType.value ? `${clientType}|${taxIdentificationNumber}` : taxIdentificationNumber,
    },
    tableNumber: tableNumber.value,
    fulfillmentMode: fulfillmentMode.value,
  }, currentLanguage.value);
}

function checkCustomerName() {
  if (customer.value.name.length >= 2) {
    showUserInfo.value = true;
    hideSummary.value = false;
  }
}

// TODO: Try to convert goBack in utility method
function goBack() {
  router.push({ name: 'OrderCartView' });
  // Trigger GTM event
  triggerGoBack(route.name);
}

onUnmounted(() => {
  hideSummary.value = true;
});
</script>

<template>
  <section class="flex flex-col justify-between h-[1690px] overflow-auto">
    <div class="options-container">
      <CollapsableContainer
        v-model="showUserInfo"
        :position="0"
        :title="t('CHECKOUT.CLIENT_INFO_TITLE')"
        @closed="checkCustomerName()"
      >
        <OrderCheckoutUserInfo
          v-model="customer"
          :show-client-type="showClientType"
          :user-fields="userFields"
          @confirm="checkCustomerName()"
        />
      </CollapsableContainer>

      <!-- Cart summary -->
      <CollapsableContainer
        v-model="hideSummary"
        :position="1"
        :title="t('CHECKOUT.SUMMARY_TITLE')"
        :disabled="!customer.name.length"
      >
        <section class="flex flex-col items-center justify-center pb-32 w-[600px] mx-auto mt-20 relative">
          <div
            class="invoice-border bg-gradient-to-b from-primary-600 to-primary-800 w-[810px] h-40 left-1/2 rounded-md flex items-center justify-center px-16 absolute z-0 -translate-x-1/2 -top-14"
          >
            <div class="w-full h-20 p-4 dark-border bg-primary-900">
              <div class="w-full h-12 bg-black" />
            </div>
          </div>

          <div class="relative z-10 bg-white summary-card">
            <div class="flex flex-col gap-2">
              <h2 class="text-[32px] leading-6 tracking-tight font-bold">
                {{ customer.name }}
              </h2>
              <span class="order-type">{{ orderType }}</span>
            </div>

            <table class="max-h-[650px] overflow-scroll">
              <thead>
                <tr class="table-header">
                  <th class="text-start">
                    {{ $t("ITEMS") }}
                  </th>
                  <th class="text-center">
                    {{ $t("QUANTITY") }}
                  </th>
                  <th class="text-end">
                    {{ $t("PRICE") }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <template
                  :key="item.id"
                  v-for="item in itemsWithCurrency"
                >
                  <tr class="table-columns">
                    <td class="pt-4 pb-2 text-start">
                      {{ item.name }}
                    </td>
                    <td class="pt-4 pb-2 text-center">
                      {{ item.quantity }}
                    </td>
                    <td class="pt-4 pb-2 text-end">
                      {{ item.price }}
                    </td>
                  </tr>

                  <template v-if="item.modifiers && item.modifiers.length > 0">
                    <OrderCheckoutRow
                      v-for="child in item.modifiers"
                      :key="child.id"
                      :item="child"
                      :is-child-row="false"
                      :currency="productsCurrency"
                      :decimals="priceDecimals"
                    />
                  </template>
                </template>
              </tbody>
            </table>

            <div class="divider w-[575px]" />
          </div>

          <div
            class="flex flex-col overflow-hidden summary-total"
            :class="{ '!h-28 !bottom-4': totalTaxes.tax }"
          >
            <template v-if="totalTaxes.tax">
              <div class="flex justify-between w-full h-8 font-normal text-neutral-400">
                <span class="w-5/6 text-center">{{ t("SUBTOTAL") }}</span>
                <span>{{ currencyValue(totalTaxes.subtotal!) }}</span>
              </div>

              <div class="flex justify-between w-full h-8 font-normal text-neutral-400">
                <span class="w-5/6 text-center">{{ t("TAXES") }}</span>
                <span>{{ currencyValue(totalTaxes.tax) }}</span>
              </div>
            </template>

            <div class="flex justify-between w-full">
              <span class="w-5/6 text-center">{{ t("TOTAL") }}</span>
              <span>{{ currencyValue(totalTaxes.total!) }}</span>
            </div>
            <div class="divider w-[590px]" />
          </div>
        </section>

        <div class="payment-options">
          <div
            class="option"
            v-if="!hideCash"
          >
            <span class="block my-6 text-4xl text-center">{{ t('PAY_ON_SITE') }}</span>

            <KioskButton
              icon="cash"
              color="outline"
              @click="selectPaymentMethod(true)"
            >
              {{ t('CASH') }}
            </KioskButton>
          </div>

          <div class="option">
            <KioskImage
              v-if="paymentImage"
              :src="paymentImage"
              alt="Payment image"
              hide-border
              class="w-full mx-auto"
              loader-size="small"
            />

            <KioskButton
              icon="credit-card"
              color="primary"
              class="w-full mx-auto"
              @click="selectPaymentMethod(false)"
            >
              {{ t('CARD') }}
            </KioskButton>
          </div>
        </div>
      </CollapsableContainer>
    </div>

    <SlideTransition leave-animation="fadeOut">
      <OrderBottomActions
        v-if="showBackActions"
        :back-label="$t('ORDER.BACK_CART')"
        :show-next-button="false"
        @back-pressed="goBack()"
      />
    </SlideTransition>
  </section>

  <OrderCheckoutModal
    v-if="paymentInProgress || jobResponse"
    :customer="customer"
    :order-total="totalTaxes"
    @retry-cash="selectPaymentMethod(true)"
    @retry-card="selectPaymentMethod(false)"
  />
</template>

<style scoped>
.options-container {
  @apply flex flex-col my-12 z-20 bg-white;
}

.options-container:last-child {
  @apply flex-grow;
}

.summary-card {
  @apply shadow-neutral-400/30 shadow-2xl;
  @apply p-12 border border-neutral-500/50 rounded-md rounded-t-none flex flex-col gap-8 w-[600px] min-h-[500px];
}

.order-type {
  @apply text-2xl leading-5 tracking-[0.5px];
}

.summary-total {
  @apply shadow-neutral-400/30 shadow-2xl z-20 absolute bottom-14 bg-white border-b-0;
  @apply px-12 font-medium border flex justify-center items-center w-[600px] h-[72px] text-3xl border-t-0 border-neutral-500/50 rounded-t-md;
}

.header-item {
  @apply text-2xl font-medium leading-8 tracking-tight text-neutral-400;
}

.summary-line {
  @apply w-[556px] border;
}

.table-header > th {
  @apply text-2xl font-medium leading-8 tracking-tight text-neutral-400 pb-5;
}

.table-columns > td {
  @apply text-2xl leading-7 tracking-[0.5px];
}

.payment-options {
  @apply flex items-end justify-center w-full px-32 mt-24;
}

.payment-options .option {
  @apply flex flex-col justify-center w-1/2 mx-5;
}

.divider {
  @apply absolute -bottom-7 z-40 left-1/2 -translate-x-1/2 border-t-8 border-spacing-0 border-neutral-500/80 border-dotted;
}

.user-checkbox {
  @apply w-7 h-7 mr-4;
}
</style>
