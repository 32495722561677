/* eslint-disable max-lines-per-function */
import { FulfillmentMode } from '@slabcode/hubster-models/enums/hubster';
import { IOrderOption } from '@/modules/order-type/types';

export const useOrderTypeStore = defineStore('orderType', () => {
  const { t } = useI18n();
  const router = useRouter();
  const { triggerAddTableNumber, triggerSelectOrderType } = useGTMEventsComposable();
  // State
  const tableNumber = ref<string>('');
  const orderTypes = ref<IOrderOption[]>([]);
  const fulfillmentMode = ref<FulfillmentMode>(FulfillmentMode.DINE_IN);

  // Getters
  const isPickupOrder = computed(() => fulfillmentMode.value === FulfillmentMode.PICKUP);
  const orderOptions = computed(() => orderTypes.value.map((type) => ({
    ...type,
    label: t(type.label),
  })));

  // Actions
  function setOrderOptions(value: IOrderOption[]) {
    orderTypes.value = value;
  }

  function setTableNumber(value: string) {
    tableNumber.value = value.toString();
    // Trigger GTM event
    if (value.length > 0) triggerAddTableNumber(value);
  }

  function setOrderTypeValue(orderType: IOrderOption, hideTableNumber: boolean) {
    fulfillmentMode.value = orderType.value;
    // Trigger GTM event
    triggerSelectOrderType(orderType.value);

    if (isPickupOrder.value) {
      setTableNumber('');
      router.push({ name: 'OrderCategoriesView' });
    } else {
      const newRoute = hideTableNumber ? 'OrderCategoriesView' : 'OrderTableView';
      router.push({ name: newRoute });
    }
  }

  return {
    orderTypes,
    orderOptions,
    tableNumber,
    fulfillmentMode,
    isPickupOrder,
    setOrderOptions,
    setOrderTypeValue,
    setTableNumber,
  };
});
